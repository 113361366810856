/* app.css */

/* Import Open Sans font */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

/* Other CSS styles */
/* ... */
body {
  font-family: "Open Sans", sans-serif;
  color: #333333;
}
.MuiCardActionArea-root {
  font-family: "Open Sans", sans-serif;
  color: #333333;
}
:root {
  --btn-color: #04bef8;
  --txt-color: #fff;
  --txt-color-p: #828282; /*opacity*/
  --footertextcolor: #333333;
  --footerbackgroundcolor: #f9f9f9;
  --homebackcolor: #002448;
  --greycolor: #f2f2f2;
  --border-lightskyblur: #97e7ff;
}
.gradient-text {
  font-size: 29px;
  font-weight: 700;
  background-image: linear-gradient(
    to right,
    #04bef7,
    #ffc407,
    #fe5c36
  ); /* Adjust colors as needed */
  -webkit-background-clip: text; /* For Webkit browsers (Chrome, Safari) */
  background-clip: text;
  color: transparent;
  width: fit-content;
}
.app {
  font-family: sans !important;
}
/* paginations styles */
.mainPageCont {
  display: flex;
  justify-content: center;
}

.mainPageCont li {
  list-style-type: none;
  /* padding: 5px 13px; */
  cursor: pointer;
  margin-left: 1rem;
  font-size: 20px;
  display: flex;
}
.mainPageCont > .previous a, .next a{
  padding: 8px 13px;
}
.mainPageCont > .previous,
.next {
  background: #ffff;
  /* padding:5px 15px 5px 10px!important; */
  border: 1px solid var(--border-lightskyblur);
  border-radius: 50%;
  color: var(--btn-color);
  display: flex;
  align-items: center;
}
.mainPageCont > .previous a,
.next a {
  display: flex;
}
.pageClassName {
  border-radius: 50%;
}
.pageClassName a {
  color: var(--footertextcolor);
  border-radius: 50%;
  border: 1px solid var(--border-lightskyblur);
  padding: 5px 13px;
}

.activeClassName a {
  color: #fff;
  background-color: var(--btn-color);
  border-radius: 0.5rem !important;
}
.activeLinkClassName {
  color: #fff;
  background-color: var(--btn-color);
  border-radius: 0.5rem !important;
  /* font-size: 20px; */
}

.co-image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 300px;
}

.co-image-container {
  width: calc(25% - 10px);
  margin-bottom: 10px;
  overflow: hidden;
}

.co-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* date range picker  */
/* .DateInput_input{
  width: 105px;
} */
/* #daterange .DateInput_input__small{
  width: 85% !important;
  color: #003556;
  font-weight: 300;
}
#daterange .DateInput_input__small::placeholder{
  color: #003556;
  font-weight: 400;
}
.start-end-dates .DateInput_input__small{
  width: 85% !important;
  color: #003556;
  font-weight: 300;
}
.start-end-dates-admin .DateInput_input__small{
  width: 100% !important;
  color: #003556;
  font-weight: 300;
  padding-right: 0rem;
}
.start-end-dates .DateInput_input__small::placeholder{
  color: #003556;
  font-weight: 400;
}
.start-end-dates-admin .DateInput_input__small::placeholder{
  color: #003556;
  font-weight: 400;
} */
.DateRangePickerInput {
  border: none !important;
  background-color: #f2f2f2 !important;
  border-radius: 0.5rem !important;
  /* border:none!important; */
}
.DateInput {
  margin-left: 0.4rem !important;
  margin-right: 0.4rem !important;
  background-color: #f2f2f2 !important;
}
#startdate {
  width: 100px;
  padding: 5px 10px;
  /* border-radius: 1rem; */
  margin-left: 1rem;
  background-color: #f2f2f2 !important;
  color: rgb(51, 51, 51);
  opacity: 0.7 !important;
  font-size: 17px;
  height: 28px;
}
#enddate {
  width: 100px;
  padding: 5px 5px;
  /* border-radius: 0.5rem; */
  margin-left: 1rem;
  background-color: #f2f2f2 !important;
  color: rgb(51, 51, 51);
  opacity: 0.7 !important;
  font-size: 17px;
  height: 28px;
}
@media screen and (min-width: 1336px) and (max-width: 4920px) {
  .DateRangePicker {
    width: 100% !important;
  }
  .DateRangePicker .div {
    width: 100%;
  }
  .DateRangePickerInput {
    width: 100%;
  }
}

@media screen and (min-width: 1285px) and (max-width: 1335px) {
  .DateRangePickerInput {
    border: none !important;
    border-radius: 0.2rem !important;
  }
  .DateInput {
    margin-left: 0.2rem !important;
    margin-right: 0.2rem !important;
  }
  #startdate {
    width: 80px;
    padding: 2px 5px;
    margin-left: 1rem;
    opacity: 0.7 !important;
    font-size: 17px;
    height: 34px;
  }
  #enddate {
    width: 80px;
    padding: 2px 5px;
    margin-left: 1rem;
    opacity: 0.7 !important;
    font-size: 17px;
    height: 34px;
  }
}
@media screen and (min-width: 1230px) and (max-width: 1284px) {
  .DateInput {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    width: 44% !important;
  }
  #startdate {
    width: 90%;
    padding: 2px 5px;
    /* margin-left: 1rem; */
    opacity: 0.7 !important;
    font-size: 17px;
  }
  #enddate {
    width: 90%;
    padding: 2px 5px;
    /* margin-left:1rem; */
    opacity: 0.7 !important;
    font-size: 17px;
  }
}
@media screen and (min-width: 1130px) and (max-width: 1229px) {
  .DateInput {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    width: 44% !important;
  }
  #startdate {
    width: 85%;
    padding: 2px 5px;
    /* margin-left: 1rem; */
    opacity: 0.7 !important;
    font-size: 17px;
  }
  #enddate {
    width: 85%;
    padding: 2px 5px;
    /* margin-left:1rem; */
    opacity: 0.7 !important;
    font-size: 17px;
  }
}
@media screen and (min-width: 1030px) and (max-width: 1129px) {
  .DateInput {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    width: 44% !important;
  }
  #startdate {
    width: 80%;
    padding: 2px 5px;
    /* margin-left: 1rem; */
    opacity: 0.7 !important;
    font-size: 17px;
  }
  #enddate {
    width: 80%;
    padding: 2px 5px;
    /* margin-left:1rem; */
    opacity: 0.7 !important;
    font-size: 17px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1029px) {
  .DateInput {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    width: 44% !important;
  }
  #startdate {
    width: 90%;
    padding: 2px 5px;
    margin-left: 0rem;
    opacity: 0.7 !important;
    font-size: 17px;
  }
  #enddate {
    width: 80%;
    padding: 2px 5px;
    margin-left: 0rem;
    opacity: 0.7 !important;
    font-size: 17px;
  }
}
@media screen and (min-width: 600px) and (max-width: 899px) {
  .DateInput {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    width: 40% !important;
  }
  #startdate {
    width: 90%;
    padding: 2px 5px;
    margin-left: 0rem;
    opacity: 0.7 !important;
    font-size: 17px;
  }
  #enddate {
    width: 95%;
    padding: 2px 5px;
    margin-left: 0rem;
    opacity: 0.7 !important;
    font-size: 17px;
  }
}
@media screen and (min-width: 200px) and (max-width: 599px) {
  .DateInput {
    /* margin-left: 0rem !important; */
    /* margin-right:0rem !important; */
    width: 40% !important;
  }
  #startdate {
    width: 100%;
    padding: 2px 5px;
    margin-left: 0rem;
    opacity: 0.7 !important;
    font-size: 17px;
  }
  #enddate {
    width: 95%;
    padding: 2px 5px;
    margin-left: 0rem;
    opacity: 0.7 !important;
    font-size: 17px;
  }
}
/* admin piechart styles */
.MuiChartsTooltip-root {
  background-color: #f2f2f2 !important;
  border-radius: 0.9rem !important;
  border: none !important;
}
.MuiChartsTooltip-root .MuiChartsTooltip-labelCell {
  display: none !important;
}
.MuiChartsTooltip-root .MuiChartsTooltip-valueCell {
  padding-left: 0.6rem !important;
}
.no_margin_child > * {
  margin: 0;
}
.customTableLabelClick {
  cursor: pointer;
}
.customTableLabelClick:hover {
  text-decoration: underline;
  color: #04bef7;
}
.yourmoca_logo {
  width: 5.25rem;
}
.moca_logo_beta {
  position: absolute;
  right: -0.3rem;
  bottom: -0.3rem;
  font-style: italic;
  font-size: 0.8rem;
  color: #333333;
}
@media screen and (min-width: 1600px) {
  .yourmoca_logo {
    width: 6.25rem;
  }
}
@media screen and (max-width: 335px) {
  .yourmoca_logo {
    width: 4.5rem;
  }
}
.max-limit {
  color: #858585;
  font-weight: 400;
}
.audio-player-bg::-webkit-media-controls-play-button,
.audio-player-bg::-webkit-media-controls-panel {
  background-color: #ffffff;
}
.grecaptcha-badge {
  display: none !important;
}
.break-all {
  max-width: 100% !important;
  word-break: break-all !important;
}