.footer_text_style {
  text-decoration: none;
  color: var(--footertextcolor);
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer_text_active{
  text-decoration: none;
  color: var(--footertextcolor);
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
}
.footer_background_color {
  background-color: var(--footerbackgroundcolor);
  /* height:'489px'; */
  border-top: 1px solid #f2f2f2;
}
.copytext {
  color: var(--txt-color-p);
  font-size: 14px;
}
.button_media {
  width: 12rem;
}
.margintopaccount {
  margin-top: 2.5rem;
}
/* .footer_media{
    align-self: flex-start;
    justify-content: flex-start;
    padding-top: 0.6rem; */
/* // sx={{display:'flex',paddingTop:{md:"0.6rem", xl: "3rem"}}} */
/* } */

/* account success styles */
.gradient-text {
  font-size: 36px;
  font-weight: 700;
  background-image: linear-gradient(
    to right,
    #04bef7,
    #ffc407,
    #fe5c36
  ); /* Adjust colors as needed */
  -webkit-background-clip: text; /* For Webkit browsers (Chrome, Safari) */
  background-clip: text;
  color: transparent;
  width: fit-content;
}
.centergrid {
  display: flex;
  flex-direction: column !important;
  justify-content: space-evenly;
}
.account_success_text {
  font-style: "openSans";
  font-weight: 300;
  font-size: 25px;
  color: #333333;
  margin: 0px;
  opacity: 70%;
}
.acountsuccessfontsyle {
  font-size: 26px;
  font-weight: 700;
  opacity: 70%;
}
/* account success styles ended here */
/* how it works styles */
.unOrdered_list {
  list-style: none;
  padding-left: 1.5rem;
}
.unOrdered_list li {
  color: #1b1b1b;
  font-size: 1rem;
  font-weight: 300;
}
.unOrdered_list li::before {
  content: "\2022";
  color: #04bef8;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5rem;
  vertical-align: sub;
}
.unOrdered_list li {
  margin-top: 1.5rem;
}
/* how it works styles ended here */

/* media quier */
@media screen and (min-width: 900px) and (max-width: 1120px) {
  .button_media {
    width: 120px;
  }
}
@media screen and (min-width: 1121px) and (max-width: 1350px) {
  .button_media {
    width: 140px;
  }
}
@media screen and (min-width: 600px) and (max-width: 899px) {
  .acountsuccessfontsyle {
    font-size: 20px;
  }
  .account_success_text {
    font-size: 20px;
  }
  .gradient-text {
    font-size: 30px;
  }
  .button_media {
    width: 110px;
  }
  .footer_logo {
    width: 70px;
  }
}
@media screen and (min-width: 100px) and (max-width: 599px) {
  .copytext {
    font-size: 12px;
  }
  .margintopaccount {
    margin-top: 1.5rem;
  }
  .acountsuccessfontsyle {
    font-size: 15px;
  }
  .account_success_text {
    font-size: 15px;
  }
  .gradient-text {
    font-size: 20px;
  }
  .button_media {
    width: 120px;
    height: 35px !important;
  }
  .footer_logo {
    width: 70px;
  }
}
